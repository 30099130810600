/*
 * @Date: 2022-10-11 09:47:51
 * @LastEditors: ZhangYaoFu
 * @LastEditTime: 2022-10-31 10:22:19
 * @Description: file content
 */
import store from '../store'
export default {
    async install(app) {
        let axios = app.config.globalProperties.axios;

        app.config.globalProperties.location = store.state.location;
        app.config.globalProperties.getLocation = async () => {
            let { data } = await axios.get(`api/common/getLocation`)
            if (data.code == '0') {
                if (data.data) {
                    // data.data.split()
                }
                return data.data;
            } else {
                return ''
            }
        }
        app.config.globalProperties.getDatadic = async (key) => {
            let { data } = await axios.get(`api/common/list?key=${key}`)
            if (data.code == '0') {
                return data.data;
            } else {
                return []
            }
        }
        app.config.globalProperties.getCategory = async (name) => {
            let { data } = await axios.get(`api/common/getComCategory`, {
                params: {
                    name
                }
            })
            if (data.code == '0') {
                return data.data;
            } else {
                return []
            }
        }
        let { data } = await app.config.globalProperties.getLocation()
        let location = store.state.location = data.data || {
            province: '陕西省',
            city: '西安市'
        }
        
        app.config.globalProperties.location = location;

    }
}