/*
 * @Date: 2022-10-11 09:47:51
 * @LastEditors: ZhangYaoFu
 * @LastEditTime: 2022-10-31 09:57:18
 * @Description: file content
 */
import { createStore } from 'vuex'
import areaJson from '@/assets/area-full.json'

export default createStore({
  state: {
    showLoading: false,
    location: {
      province: '陕西省',
      city: '西安市'
    }
  },
  getters: {
    provinces() {
      return ['陕西省', '甘肃省', '青海省', '宁夏回族自治区', '新疆维吾尔自治区']
    },
    cities(state) {
      let p = areaJson.find(p => p.label == state.location.province);
      return p.children.map(c => c.label)
    },
    areas(state) {
      let p = areaJson.find(p => p.label == state.location.province);
      return p.children.find(c => c.label == state.location.city).children.map(a => a.label);
    }
  },
  mutations: {

  },
  actions: {
  },
  modules: {
  }
})
