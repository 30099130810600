import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import constants from './plugins/constants'
import axios_plugin from './plugins/axios_plugin'
import common from './plugins/common'
import ElementUIPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


import 'element-plus/dist/index.css'

app.use(store).use(router).use(constants).use(axios_plugin).use(common).use(ElementUIPlus).mount('#app')
