import axios from 'axios'
import {ElMessage as Message} from 'element-plus';
import store from '../store'

export default {
    install(app) {
        store.state.showLoading = false;
        let loadingTimeout = 1000; //加载动画出现阈值
        let loadingTimeouts = []

        axios.defaults.baseURL = app.config.globalProperties.ctx;
        axios.defaults.timeout = 50000;
        // axios.defaults.withCredentials = true

        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

        axios.interceptors.request.use(function(config) {
            // config.headers['token'] = vm.token;
            // 
            let timeoutId = setTimeout(() => {
                store.state.showLoading = true;
                
            }, loadingTimeout);

            let timeoutJson = {}
            timeoutJson = {
                url: config.url,
                timeoutId
            };
            loadingTimeouts.push(timeoutJson);

            return config;
        })
        axios.interceptors.response.use(function(response) {
            let timeoutJson = loadingTimeouts.find(timeoutJson => timeoutJson.url == response.config.url)
            if (timeoutJson) {
                // for (let index = 0; index < loadingTimeouts.length; index++) {
                //     const json = loadingTimeouts[index];
                //     if (json.timeoutId == timeoutJson.timeoutId) {
                //         loadingTimeouts.splice(index, 1);
                //         break;
                //     }
                // }
                if (loadingTimeouts.indexOf(timeoutJson) != -1 && store.state.showLoading) {
                    store.state.showLoading = false;
                    
                }
                clearTimeout(timeoutJson.timeoutId);
                loadingTimeouts.splice(loadingTimeouts.indexOf(timeoutJson), 1);
            }
            // 
            let data = response.data;
            if (data.code != '0') {
                if (data.message) {

                    Message.error(data.message)
                }
            }

            return response;
        }, function(error) {
            let errmsg = (error.response && error.response.data && error.response.data.message) || error.message;
            if (typeof errmsg == 'string') {
                if (errmsg == 'Network Error') {
                    errmsg = '网络错误,请检查网络是否通畅或服务器是否启动'
                }
                loadingTimeouts.forEach(timeoutJson => {
                    clearTimeout(timeoutJson.timeoutId);
                })
                store.state.showLoading = false;
            }
            if (error.response) {
                let response = error.response;
                let timeoutJson = loadingTimeouts.find(timeoutJson => timeoutJson.url == response.config&&response.config.url)
                if (timeoutJson) {
                    // for (let index = 0; index < loadingTimeouts.length; index++) {
                    //     const json = loadingTimeouts[index];
                    //     if (json.timeoutId == timeoutJson.timeoutId) {
                    //         loadingTimeouts.splice(index, 1);
                    //         break;
                    //     }
                    // }
                    if (loadingTimeouts.indexOf(timeoutJson) != -1 && store.state.showLoading) {
                        store.state.showLoading = false;
                    }
                    clearTimeout(timeoutJson.timeoutId);
                    loadingTimeouts.splice(loadingTimeouts.indexOf(timeoutJson), 1);
                }
                if(!response.config){
                    store.state.showLoading = false;
                }
            }
            Message.error(errmsg);
            return Promise.reject(error);
        })
        
        app.config.globalProperties.axios = axios //axios
    }
}