/*
 * @Date: 2022-10-11 09:47:51
 * @LastEditors: ZhangYaoFu
 * @LastEditTime: 2022-10-11 10:16:36
 * @Description: file content
 */
export default {
    install(app) {
        // app.config.globalProperties.ctx = 'http://localhost:8082/'
        // app.config.globalProperties.ctx = 'http://192.168.30.55:8082/'
        app.config.globalProperties.ctx = 'https://api.xbzhjs.com/'
        // app.config.globalProperties.ctx = 'https://34c9-61-150-60-9.jp.ngrok.io/'
        app.config.globalProperties.$codeDic = {
            '场馆类型': '00010',

            '场地类型': '00011',
            '专项活动场地': '00016',

            '赛事类型': '00074',
            '体检类型': '00023',
            '组织类型': '00027',
            '指导员类型': '00046',
            '专家类型': '00035',
            '指导项目': '00044',

            '健身科普': '00089',

            '政策体系': '00088',
        }
    }
}