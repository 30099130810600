import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta:{
      title:'首页'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta:{
      title:'关于我们'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/study',
    name: 'study',
    meta:{
      title:'健身科普'
    },
    component: () => import('../views/StudyView.vue'),
    children:[
      {
        path: '/study',
        name: 'studyarea',
        meta:{
          title:'健身科普',
          tagtype:'study'
        },
        component: () => import('../views/Study/StudyView.vue'),
      },
      {
        path: 'studynews/:category',
        name: 'studynews',
        meta:{
          title:'健身科普',
          showBread:false,
          tagtype:'study'
        },
        component: () => import('../views/Study/StudyNewsView.vue'),
        children:[
          {
            name:'studynewsDetail',
            meta:{
              title:'详情',
              showBread:false,
              tagtype:'study'
            },
            path:':id', 
            component: () => import('../views/Study/StudyNewsView.vue')
          }
        ]
      },
    ]
  },
  {
    path: '/sport',
    name: 'sport',
    meta:{
      title:'健身地图'
    },
    component: () => import('../views/SportView.vue')
  },
  {
    path: '/find',
    name: 'find',
    component: () => import('../views/Find/FindView.vue'),
    meta:{
      // showBread:true
    },
    children:[
      {
        path: 'expert',
        name: 'expertContent',
        meta:{
          title:'找专家',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'expert',
            component: () => import('../views/Find/FindExpertView.vue'),
            meta:{
              title:'找专家',
            },
          },
          {
            path:':id',
            name:'expertDetail',
            component: () => import('../views/Find/FindExpertDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          }
        ]
      },
      {
        path: 'match',
        name: 'matchContent',
        meta:{
          title:'找赛事',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'match',
            component: () => import('../views/Find/FindMatchView.vue'),
            meta:{
              title:'找赛事',
            },
          },
          {
            path:'list',
            name:'matchList',
            component: () => import('../views/Find/FindMatchListView.vue'),
            meta:{
              title:'列表',
              showBread:true
            },
          },
          {
            path:':id',
            name:'matchDetail',
            component: () => import('../views/Find/FindMatchDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          },
          {
            path: 'matchnews/:category',
            name: 'matchnews',
            meta:{
              title:'赛事新闻',
              name:'matchnews',
              showBread:true
            },
            component: () => import('../views/Find/FindMatchNewsView.vue'),
            children:[
              {
                name:'matchnewsDetail',
                meta:{
                  title:'新闻详情',
                  name:'matchnews',
                  showBread:true
                },
                path:':id', 
                component: () => import('../views/Find/FindMatchNewsView.vue')
              }
            ]
          },

        ]
      },
      {
        path: 'stadium',
        name: 'stadiumContent',
        meta:{
          title:'找场馆',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'stadium',
            component: () => import('../views/Find/FindStadiumView.vue'),
            meta:{
              title:'找场馆',
            },
          },
          {
            path:':id',
            name:'stadiumDetail',
            component: () => import('../views/Find/FindStadiumDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          }
        ]
      },
      {
        path: 'healthcheck',
        name: 'healthcheckContent',
        meta:{
          title:'找体检',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'healthcheck',
            component: () => import('../views/Find/FindHealthCheckView.vue'),
            meta:{
              title:'找体检',
            },
          },
          {
            path:':id',
            name:'healthcheckDetail',
            component: () => import('../views/Find/FindHealthCheckDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          }
        ]
      },
      {
        path: 'place',
        name: 'placeContent',
        meta:{
          title:'找设施',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'place',
            component: () => import('../views/Find/FindPlaceView.vue'),
            meta:{
              title:'找设施',
            },
          },
          {
            path:'equip/:id',
            name:'equipDetail',
            component: () => import('../views/Find/FindPlaceEquipDetailView.vue'),
            meta:{
              title:'设施详情',
              showBread:true
            },
          },
          {
            path:'list',
            name:'placeList',
            component: () => import('../views/Find/FindPlaceListView.vue'),
            meta:{
              title:'列表',
              showBread:true
            },
          },
          {
            path:':id',
            name:'placeDetail',
            component: () => import('../views/Find/FindPlaceDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          }
        ]
      },
      {
        path: 'guide',
        name: 'guideContent',
        meta:{
          title:'找指导',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'guide',
            component: () => import('../views/Find/FindGuideView.vue'),
            meta:{
              title:'找指导',
            },
          },
          {
            path:'list',
            name:'guideList',
            component: () => import('../views/Find/FindGuideListView.vue'),
            meta:{
              title:'列表',
            },
          },
          {
            path:':id',
            name:'guideDetail',
            component: () => import('../views/Find/FindGuideDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          }
        ]
      },
      {
        path: 'org',
        name: 'orgContent',
        meta:{
          title:'找组织',
        },
        component: () => import('../views/Find/FindContentView.vue'),
        children:[
          {
            path:'',
            name:'org',
            component: () => import('../views/Find/FindOrgView.vue'),
            meta:{
              title:'找组织',
            },
          },
          {
            path:'list',
            name:'orgList',
            component: () => import('../views/Find/FindOrgListView.vue'),
            meta:{
              title:'列表',
              showBread:true
            },
          },
          {
            path:':id',
            name:'orgDetail',
            component: () => import('../views/Find/FindOrgDetailView.vue'),
            meta:{
              title:'详情',
              showBread:true
            },
          },
          {
            path:'newslist/:category',
            name:'orgNewsList',
            component: () => import('../views/Find/FindOrgNewsListView.vue'),
            meta:{
              title:'新闻列表',
              showBread:true 
            }
          },
          {
            path:'orgnews/:category/:id',
            name:'orgNewsListDetail',
            meta:{
              title:'新闻详情',
              showBread:true
            },
            component: () => import('../views/Find/FindOrgNewsDetailView.vue')
          }
        ]
      },
      // {
      //   path: 'guide',
      //   name: 'guide',
      //   meta:{
      //     title:'找专家',
      //   },
      //   component: () => import('../views/Find/FindGuideView.vue'),
      //   children:[
      //     {
      //       path:':name',
      //       name:'guideDetail',
      //       component: () => import('../views/Find/FindGuideView.vue'),
      //       meta:{
      //         title:'详情',
      //         showBread:true
      //       },
      //     }
      //   ]
      // },
    ]
  },
  {
    path: '/newspolicy',
    name: 'newspolicy',
    meta:{
      // title:'新闻资讯',
    },
    component: () => import('../views/NewsPolicy/NewsPolicyView.vue'),
    children:[
      {
        path: 'news/:category',
        name: 'news',
        meta:{
          title:'新闻资讯',
        },
        component: () => import('../views/NewsPolicy/NewsView.vue'),
        children:[
          {
            name:'newsDetail',
            meta:{
              title:'新闻详情',
              showBread:true
            },
            path:':id',
            component: () => import('../views/NewsPolicy/NewsView.vue')
          }
        ]
      },
      {
        path: 'policy',
        name: 'policyContent',
        meta:{
          title:'政策公告',
        },
        component: () => import('../views/NewsPolicy/PolicyContentView.vue'),
        children:[
    
          {
            name:'policy',
            meta:{
              title:'政策公告',
            },
            path:'',
            component: () => import('../views/NewsPolicy/PolicyView.vue')
          },
          {
            name:'policyDetail',
            meta:{
              title:'政策详情',
              showBread:true
            },
            path:':id',
            component: () => import('../views/NewsPolicy/PolicyDetailView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/404',
    component: () => import('../views/404.vue'),
    hidden: true
  },
  { path: '/:pathMatch(.*)', redirect: '/404', hidden: true }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(){
    // 始终滚动到顶部
    return { top: 0 }
  },
})

router.beforeEach((to,from,next)=>{ 
  if(to.matched.length === 0){
      from.path ? next({name: from.name}) : next('/404')
  }else{
      next()
  }
})
export default router
